import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { Link } from 'gatsby';
import { EuiSpacer } from '@elastic/eui';
import NavButtons from 'components/Advanced_User/NavButtons';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
import DevArticles from 'components/Advanced_User/All_Home_Automation_Tutorials/IntroDevSmart';
import MqttTimeLine from 'components/Products/CommentList/MqttUpdatesList';
import MqttIftttTimeLine from 'components/Products/CommentList/MqttIFTTTList';
import AlexaIftttTimeLine from 'components/Products/CommentList/HomekitAlexaList';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <SEOHelmet title='Smarthome Articles for Developers' dateChanged='2021-05-05' author='Mike Polinowski' tag='INSTAR IP Camera' description='Adding your INSTAR IP Camera to your Home Automation System of Choice.' image='/images/Search/P_SearchThumb_Smarthome.png' twitter='/images/Search/P_SearchThumb_Smarthome.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Erweitert/Alle_Smarthome_Tutorials/' locationFR='/fr/Advanced_User/All_Home_Automation_Tutorials/' crumbLabel="Smarthome" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <DevArticles mdxType="DevArticles" />
    <EuiSpacer mdxType="EuiSpacer" />
    <MqttTimeLine mdxType="MqttTimeLine" />
    <EuiSpacer mdxType="EuiSpacer" />
    <MqttIftttTimeLine mdxType="MqttIftttTimeLine" />
    <EuiSpacer mdxType="EuiSpacer" />
    <AlexaIftttTimeLine mdxType="AlexaIftttTimeLine" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      